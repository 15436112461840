import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import Nav from '../components/nav'
import Img from "gatsby-image"
import Footer from '../components/footer'

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <Nav />
            <div className="layout">
				<div className="layout__wide padding__large">
					<div className="flex flex__space-between">
						<div className="col__80 body">
							<h1>{ entry.frontmatter.heading }</h1>
						</div>
					</div>
				</div>
            </div>
			<figure className="layout">
                <div className="layout gradient">
                    <Img fluid={entry.frontmatter.image.childImageSharp.fluid} alt={entry.frontmatter.heading} />
                </div>
            </figure>
			<main className="layout">
				<div className="layout__wide padding__large">
					<div className="flex flex__space-between">
						<div className="col__60 body">
							<div dangerouslySetInnerHTML={{ __html: entry.html }} />
						</div>
					</div>
				</div>
            </main>
            <section className="layout padding__large--bottom">
				<div className="layout__wide">
                    <div className="products">
                        {entry.frontmatter.categories.map( (p, i) => (
                            <div className="product" key={i}>
                                <Link to={p.link} className="product__link">
                                    <figure>
                                        <Img fluid={p.image.childImageSharp.fluid} alt={p.heading} />
                                    </figure>
                                    <h2>{p.heading}</h2>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark( fields: { slug: { eq: $slug } }) {
            frontmatter {
                title
                page_title
                meta_description
                heading
                image {
                    childImageSharp {
                        fluid(maxWidth: 800, maxHeight: 450) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                categories {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 640, maxHeight: 360) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    heading
                    link
                }
            }
            fields {
                slug
            }
            html
        }
    }
`